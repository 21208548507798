import React, { useEffect, useMemo } from "react";
import { Button, Modal, Popconfirm, Popover, Select } from "antd";
import { AiFillQuestionCircle, AiOutlineQuestionCircle } from "react-icons/ai";
import ReactGA from "react-ga4";
import Link from "./Link";

export default function ({ ...props }) {
  const [showMoney, setShowMoney] = React.useState(false);
  const [btnDisabled, setBtnDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [showResult, setShowResult] = React.useState(false);
  const ref = React.useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [form, setForm] = React.useState({
    pemberi: undefined,
    jenisBarang: undefined,
    momen: undefined,
    coi: undefined,
    money: undefined,
  });
  const optPemberi = useMemo(
    () => [
      {
        value: "partner",
        label: "Rekan Kerja",
        result: true,
        score: 0,
        rules: [
          {
            value: ["prize", "promotion", ">1jt"],
            score: 100, //no 7
          },
          {
            value: ["prize", "promotion", "300rb-1jt"],
            score: 100, //no 7
          },
          {
            value: ["prize", "workshop", ">1jt"],
            score: 100, //no 8
          },
          {
            value: ["prize", "workshop", "300rb-1jt"],
            score: 100, //no 8
          },
          {
            value: ["promotion", ">1jt", "no"],
            score: 100, //no 21
          },
        ],
      },
      {
        value: "vendor",
        label: "Vendor",
        result: true,
        score: 100,
        rules: [
          {
            value: ["interest", "non_work_related", "no"],
            score: -100, //no 26
          },
          {
            value: ["prize", "culture", "no", "<300rb"],
            score: -100, //no 29
          },
          {
            value: ["prize", "culture", "no", "300rb-1jt"],
            score: -100, //no 29
          },
          {
            value: ["award", "promotion", "no", "<300rb"],
            score: -100, //no 32
          },
          {
            value: ["award", "culture", "no", "<300rb"],
            score: -100, //no 34
          },
          {
            value: ["award", "culture", "no", "300rb-1jt"],
            score: -100, //no 34
          },
          {
            value: ["award", "non_work_related", "no"],
            score: -100, //no 36
          },
        ],
      },
      {
        value: "keluarga_kandung",
        label: "Keluarga Kandung",
        result: true,
        score: -100,
      },
      {
        value: "teman",
        label: "Teman/Sahabat",
        result: true,
        score: 0,
      },
      {
        value: "pegawai_external",
        label: "Pegawai kantor lain",
        result: true,
        score: 100,
        rules: [
          //no 58
          {
            value: ["interest", "non_work_related", "no"],
            score: -100, //no 26
          },
          {
            value: ["prize", "culture", "no", "<300rb"],
            score: -100, //no 29
          },
          {
            value: ["prize", "culture", "no", "300rb-1jt"],
            score: -100, //no 29
          },
          {
            value: ["award", "promotion", "no", "<300rb"],
            score: -100, //no 32
          },
          {
            value: ["award", "culture", "no", "<300rb"],
            score: -100, //no 34
          },
          {
            value: ["award", "culture", "no", "300rb-1jt"],
            score: -100, //no 34
          },
          {
            value: ["award", "non_work_related", "no"],
            score: -100, //no 36
          },
        ],
      },
      {
        value: "sepupu",
        label: "Sepupu",
        result: true,
        score: -100,
      },
      {
        value: "unknown",
        label: "Orang tidak dikenal",
        result: true,
        score: 100,
        rules: [
          //no 58
          {
            value: ["interest", "non_work_related", "no"],
            score: -100, //no 26
          },
          {
            value: ["prize", "culture", "no", "<300rb"],
            score: -100, //no 29
          },
          {
            value: ["prize", "culture", "no", "300rb-1jt"],
            score: -100, //no 29
          },
          {
            value: ["award", "promotion", "no", "<300rb"],
            score: -100, //no 32
          },
          {
            value: ["award", "culture", "no", "<300rb"],
            score: -100, //no 34
          },
          {
            value: ["award", "culture", "no", "300rb-1jt"],
            score: -100, //no 34
          },
          {
            value: ["award", "non_work_related", "no"],
            score: -100, //no 36
          },
        ],
      },
    ],
    []
  );
  const optJenisBarang = useMemo(
    () => [
      {
        value: "interest",
        label: "Keuntungan/Bunga Investasi",
        result: true,
        showMoney: true,
        score: 0,
      },
      {
        value: "prize",
        label: "Hadiah non-uang/alat tukar lainnya/Kejuaraan",
        result: true,
        showMoney: true,
        score: 0,
        rules: [
          {
            value: ["teman", "promotion", "no"],
            score: 100, //no 46
          },
          {
            value: ["teman", "culture", "no", ">1jt"],
            score: 100, //no 48
          },
        ],
      },
      {
        value: "banquet",
        label: "Hidangan/sajian",
        showMoney: false,
        result: true,
        score: 0,
      },
      {
        value: "award",
        label: "Penghargaan berupa uang/barang",
        showMoney: true,
        result: true,
        score: 0,
        rules: [
          {
            value: [">1jt"],
            score: 100, //no 57
          },
        ],
      },
      {
        value: "cash",
        label: "Uang dalam bentuk apapun (tunai, transfer, cek, dll)",
        showMoney: true,
        result: true,
        score: 0,
      },
    ],
    []
  );
  const optMomen = useMemo(
    () => [
      {
        value: "promotion",
        label: "Mutasi/Promosi",
        result: true,
        score: 0,
      },
      {
        value: "workshop",
        label: "Seminar/Workshop/Pelatihan/Konferensi",
        result: true,
        score: 0,
      },
      {
        value: "culture",
        label: "Pernikahan/upacara adat/tradisi budaya",
        result: true,
        score: 0,
      },
      {
        value: "disaster",
        label: "Musibah/Bencana",
        result: true,
        score: -1000,
      },
      {
        value: "work_related",
        label: "Terkait kedinasan",
        result: true,
        score: 1000,
      },
      {
        value: "non_work_related",
        label: "Tidak terkait kedinasan",
        result: true,
        score: 0,
      },
    ],
    []
  );
  const optCOI = useMemo(
    () => [
      {
        value: "yes",
        label: "Ya",
        result: true,
        score: 1000,
      },
      {
        value: "no",
        label: "Tidak",
        result: true,
        score: 0,
      },
    ],
    []
  );
  const optMoney = useMemo(() => [
    {
      value: "<300rb",
      label: "Dibawah 300rb rupiah",
      score: 0,
    },
    {
      value: "300rb-1jt",
      label: "300rb s.d. 1jt rupiah",
      score: 0,
    },
    {
      value: ">1jt",
      label: "Diatas 1jt rupiah",
      score: 100,
      rules: [
        {
          value: ["sepupu"],
          score: 0,
        },
        {
          value: ["keluarga_kandung"],
          score: 0,
        },
      ],
    },
  ]);
  const onChange = (value, list, key) => {
    ReactGA.event({
      category: "Isi form",
      action: key,
      label: value,
    });
    const obj = list.find((item) => item.value === value);
    let prevForm = { ...form, [key]: value };
    if (key == "jenisBarang") {
      if (obj.showMoney) setShowMoney(true);
      else {
        setShowMoney(false);
        prevForm.money = undefined;
      }
    }

    setForm(prevForm);
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };
  useEffect(() => {
    let btnDisabled = false;
    if (
      form.pemberi === undefined ||
      form.jenisBarang === undefined ||
      form.momen === undefined ||
      form.coi === undefined
    )
      btnDisabled = true;
    if (showMoney && form.money === undefined) btnDisabled = true;
    setBtnDisabled(btnDisabled);
  }, [form]);
  const handleCek = () => {
    let score = 0;
    setLoading(true);
    const values = Object.values(form);
    const keys = Object.keys(form);
    values.forEach((value, index) => {
      ReactGA.event({
        category: "Cek Pemberian",
        action: keys[index],
        label: value,
      });
    });
    ReactGA.event({
      category: "Cek Pemberian",
      action: "cek",
      label: "button_clicked",
    });
    const pemberi = optPemberi.find((item) => item.value === form.pemberi);
    const jenisBarang = optJenisBarang.find(
      (item) => item.value === form.jenisBarang
    );
    const momen = optMomen.find((item) => item.value === form.momen);
    const coi = optCOI.find((item) => item.value === form.coi);
    const money = optMoney.find((item) => item.value === form.money);
    if (pemberi) score += pemberi.score;
    if (jenisBarang) score += jenisBarang.score;
    if (momen) score += momen.score;
    if (coi) score += coi.score;
    if (money) score += money.score;

    if (pemberi?.rules) {
      const rule = pemberi.rules.find((item) =>
        item.value.every((value) => {
          return values.includes(value);
        })
      );
      // console.log("pemberi", rule);
      if (rule) score += rule.score;
    }
    if (jenisBarang?.rules) {
      const rule = jenisBarang.rules.find((item) =>
        item.value.every((value) => {
          return values.includes(value);
        })
      );
      // console.log("jenisBarang", rule);
      if (rule) score += rule.score;
    }
    if (momen?.rules) {
      const rule = momen.rules.find((item) =>
        item.value.every((value) => {
          return values.includes(value);
        })
      );
      // console.log("momen", rule);
      if (rule) score += rule.score;
    }
    if (money?.rules) {
      const rule = money.rules.find((item) =>
        item.value.every((value) => {
          return values.includes(value);
        })
      );
      // console.log("money", rule);
      if (rule) score += rule.score;
    }
    let result = false;
    if (score >= 100) result = true;
    // console.log("score", score);
    window.localStorage.setItem("result", result);
    window.localStorage.setItem("expired_at", Date.now() + 1000 * 60 * 15);
    window.localStorage.setItem("form", JSON.stringify(form));
  };
  useEffect(() => {
    setLoading(false);
    const expired_at = window.localStorage.getItem("expired_at");
    if (
      expired_at &&
      new Date().getTime() < new Date(parseInt(expired_at)).getTime()
    ) {
      setShowResult(true);
      setForm(JSON.parse(window.localStorage.getItem("form")));
      ref.current?.scrollIntoView({ behavior: "smooth" });
    } else {
      setShowResult(false);
      setForm({});
      window.localStorage.removeItem("result");
      window.localStorage.removeItem("form");
      window.localStorage.removeItem("expired_at");
    }
  }, [window.localStorage.getItem("expired_at")]);
  const surveyResult = window.localStorage.getItem("result");
  return (
    <section className="p-4" {...props}>
      <div className="m-auto max-w-xl p-4 w-auto shadow-2xl relative bottom-14 bg-white">
        <div className="mb-4 bg-[#136bc6]  text-white ">
          <div className="font-bold text-xl p-2">
            Cek Pemberian yang Kamu Terima Apakah Wajib Dilaporkan Atau Tidak
          </div>
        </div>

        <div className="flex flex-col space-y-6 max-w-lg mb-8 text-lg m-auto">
          <div className="flex md:flex-row flex-col space-y-2 text-left">
            <div className="flex w-72 font-medium items-center">Pemberi</div>
            <Select
              className="flex w-full"
              placeholder="Pilih Pemberi"
              optionFilterProp="children"
              value={form.pemberi}
              onChange={(value) => onChange(value, optPemberi, "pemberi")}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={optPemberi}
              disabled={loading || showResult}
            />
          </div>
          <div className="flex md:flex-row flex-col space-y-2 text-left">
            <div className="flex w-72 font-medium items-center">
              Jenis Barang
            </div>
            <Select
              className="flex w-full"
              placeholder="Pilih Jenis Barang"
              optionFilterProp="children"
              value={form.jenisBarang}
              onChange={(value) =>
                onChange(value, optJenisBarang, "jenisBarang")
              }
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              dropdownStyle={{
                whiteSpace: "normal",
                height: "auto",
                width: 200,
                color: "red",
              }}
              options={optJenisBarang}
              disabled={loading || showResult}
            />
          </div>
          <div className="flex md:flex-row flex-col space-y-2 text-left">
            <div className="flex w-72 font-medium items-center">
              Momen Pemberian
            </div>
            <Select
              className="flex w-full"
              placeholder="Pilih Momen"
              optionFilterProp="children"
              value={form.momen}
              onChange={(value) => onChange(value, optMomen, "momen")}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={optMomen}
              disabled={loading || showResult}
            />
          </div>
          <div className="flex md:flex-row flex-col space-y-2 text-left">
            <div className="flex w-72 font-medium items-center">
              <Popover
                title="Apa itu Conflict of Interest?"
                content={
                  <div className="max-w-md">
                    <i>Conflict of Interest</i> (COI) atau benturan kepentingan
                    adalah keadaan memiliki atau patut diduga memiliki
                    kepentingan pribadi untuk menguntungkan diri sendiri
                    dan/atau orang lain dalam penggunaan wewenang sehingga dapat
                    mempengaruhi objektivitas dan kualitas keputusan dan/atau
                    tindakan yang dilakukan dan/atau tidak dilakukannya.
                  </div>
                }
              >
                Conflict of Interest{" "}
                <AiFillQuestionCircle className=" text-[#136bc6]" />
              </Popover>
            </div>
            <Select
              // showSearch
              className="flex w-full"
              placeholder="Pilih Pilihan"
              optionFilterProp="children"
              value={form.coi}
              onChange={(value) => onChange(value, optCOI, "coi")}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={optCOI}
              disabled={loading || showResult}
            />
          </div>
          {showMoney && (
            <div className="flex md:flex-row flex-col space-y-2 text-left">
              <div className="flex w-64 font-medium items-center">
                Nilai Uang/Barang yang diterima
              </div>
              <Select
                className="flex w-full"
                placeholder="Pilih nilai"
                optionFilterProp="children"
                value={form.money}
                onChange={(value) => onChange(value, optMoney, "money")}
                options={optMoney}
                disabled={loading || showResult}
              />
            </div>
          )}
          <div ref={ref}>
            {!showResult ? (
              <div className="text-right">
                <Popconfirm
                  title="Apakah sudah benar?"
                  okText="Ya"
                  cancelText="Tidak"
                  onConfirm={handleCek}
                  placement="left"
                  disabled={btnDisabled}
                >
                  <Button
                    type="primary"
                    disabled={btnDisabled}
                    className=" rounded-md"
                  >
                    Lihat Hasil
                  </Button>
                </Popconfirm>
              </div>
            ) : (
              <div>
                <Button
                  type="default"
                  onClick={() => {
                    window.localStorage.removeItem("result");
                    window.localStorage.removeItem("form");
                    window.localStorage.removeItem("expired_at");
                    setShowResult(false);
                    setForm({});
                  }}
                  className=" rounded-md"
                >
                  Reset
                </Button>
                <div className="text-base mt-2">Hasil Pengecekan</div>
                {surveyResult === "true" ? (
                  <div className="text-2xl mb-4 font-bold text-red-600">
                    Wajib Dilaporkan!!
                  </div>
                ) : (
                  <div className="text-xl mb-4 font-bold text-green-600">
                    Tidak Wajib Dilaporkan
                  </div>
                )}
                {surveyResult === "true" && (
                  <div className="text-base">
                    Laporkan segera melalui{" "}
                    <Link
                      className="font-medium no-underline text-blue-600"
                      target="_blank"
                      href="https://gol.kpk.go.id/login"
                    >
                      Gratifikasi Online (GOL KPK)
                    </Link>{" "}
                    maksimal <strong>30 Hari Kerja</strong> atau melalui UPG di
                    Unit masing-masing maksimal <strong>10 Hari Kerja</strong>{" "}
                    setelah menerima Gratifikasi
                    <div className="grid grid-cols-2 gap-2">
                      <Link
                        className="no-underline"
                        target="_blank"
                        href="https://gol.kpk.go.id/login"
                      >
                        <img src="images/logo_gol.png" className="max-w-full" />
                      </Link>
                      <Link
                        className="no-underline"
                        target="_blank"
                        href="https://www.wise.kemenkeu.go.id/#/"
                      >
                        <img
                          src="images/wise_kemenkeu.jpg"
                          className="max-w-full"
                        />
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

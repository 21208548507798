import logo from "./logo.svg";
import "./App.css";
import Form from "./Form";
import Highlighter from "react-highlight-words";
import { FaExternalLinkAlt, FaFileAlt } from "react-icons/fa";
import AnimatedTextWord from "./AnimatedTextWord";
import React, { useEffect } from "react";
import Link from "./Link";
import axios from "axios";
const words = [
  "Anti Gratifikasi",
  "Tolak Gratifikasi",
  "Anti Korupsi",
  "BerAKHLAK",
  "Integritas",
];
function App() {
  const [currIndex, setCurrIndex] = React.useState(0);
  const [replay, setReplay] = React.useState(false);
  const [page_view, setPageView] = React.useState(undefined);
  useEffect(() => {
    setTimeout(() => {
      setReplay(false);
      setTimeout(() => {
        let current = currIndex + 1;
        if (current == words.length) {
          current = 0;
        }
        setCurrIndex(current);
        setReplay(true);
      }, 500);
    }, 3000);
  }, [currIndex]);
  //counter
  useEffect(() => {
    axios
      .get("https://analytics-api.nuriz.id/analytics/396922442/page_view")
      .then((res) => {
        setPageView(res.data.result.counter.value);
      });
  }, []);
  return (
    <div className="App">
      <header
        className="App-header border-b-4 border-white border-solid border-0"
        style={{
          background: "rgb(16,66,117)",
          background:
            "linear-gradient(90deg, rgba(16,66,117,1) 55%, rgba(14,156,230,1) 100%)",
        }}
      >
        <div className="mx-4 mt-12 md:mt-24 mb-12 border-none">
          <h1 className="font-medium text-4xl">
            Anti Gratifikasi Berbasis Website
          </h1>
          <span className=" font-thin">
            Periksa pemberian yang kamu terima apakah wajib dilaporkan atau
            tidak
          </span>
          <div className=" text-red-500 font-bold mt-16 animate-pulse text-4xl md:text-5xl">
            TOLAK GRATIFIKASI !!!
          </div>
        </div>
        <div class="custom-shape-divider-bottom-1688864165 border-none">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
              opacity=".25"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
              opacity=".5"
              class="shape-fill"
            ></path>
            <path
              d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </header>

      <section className=" text-gray-700 my-12 mb-16 border-none">
        <h2 className="text-3xl font-bold my-8">Pengertian Gratifikasi</h2>
        <div className="grid md:grid-cols-2 mb-8 place-items-center">
          <div className="m-4">
            <p className="italic mb-4">
              <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[
                  "pemberian uang, barang, rabat (discount), komisi, pinjaman tanpa bunga, tiket perjalanan, fasilitas penginapan, perjalanan wisata, pengobatan cuma-cuma, dan fasilitas lainnya",
                ]}
                autoEscape={true}
                textToHighlight="Pemberian dalam arti luas, yakni meliputi pemberian uang, barang,
              rabat (discount), komisi, pinjaman tanpa bunga, tiket perjalanan,
              fasilitas penginapan, perjalanan wisata, pengobatan cuma-cuma, dan
              fasilitas lainnya. Gratifikasi tersebut baik yang diterima di
              dalam negeri maupun di luar negeri dan yang dilakukan dengan
              menggunakan sarana elektronik atau tanpa sarana elektronik."
              />
            </p>
            <strong>- Undang-Undang Nomor 20 Tahun 2001 -</strong>
          </div>
          <div className="m-4">
            <p className="italic  mb-4">
              <Highlighter
                highlightClassName="YourHighlightClass"
                searchWords={[
                  "uang, barang, rabat (discount), komisi, pinjaman tanpa bunga, tiket perjalanan, fasilitas penginapan, perjalanan wisata, pengobatan cuma-cuma, dan fasilitas lainnya",
                  "dalam negeri",
                  "luar negeri",
                ]}
                autoEscape={true}
                textToHighlight="Gratifikasi adalah pemberian dalam arti luas meliputi uang,
              barang, rabat (discount), komisi, pinjaman tanpa bunga, tiket
              perjalanan, fasilitas penginapan, perjalanan wisata, pengobatan
              cuma-cuma, dan fasilitas lainnya, baik yang diterima di dalam
              negeri maupun di luar negeri, yang dilakukan dengan menggunakan
              sarana elektronik atau tanpa sarana elektronik baik secara
              langsung ataupun tidak langsung kepada Pegawai atau Penyelenggara
              Negara."
              />
            </p>
            <strong>- Peraturan Menteri Keuangan Nomor 227 Tahun 2021 -</strong>
          </div>
        </div>
      </section>
      <section className="bg-[#136bc6] text-white">
        <div class="custom-shape-divider-top-1688871244">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
        <div className="px-8 pb-8 ">
          <h2 className="text-3xl mt-0 font-bold my-8 mb-8">
            Perbedaannya dengan Suap dan Pungli
          </h2>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8">
            <div>
              <div className="h-24 flex justify-center flex-col items-center">
                <img src="images/suap.png" alt="suap" className="h-24" />
              </div>
              <div className="font-medium text-lg">Suap</div>
              <p>
                Suap terjadi apabila pengguna jasa secara aktif menawarkan
                imbalan kepada petugas layanan dengan maksud agar tujuannya
                lebih cepat tercapai walau melanggar prosedur.
              </p>
            </div>
            <div>
              <div className="h-24 flex justify-center flex-col items-center">
                <img src="images/pungli.png" alt="suap" className=" h-16" />
              </div>
              <div className="font-medium text-lg">Pemerasan (Pungli)</div>
              <p>
                Pemerasan/pungli terjadi apabila petugas layanan secara aktif
                menawarkan jasa atau meminta imbalan kepada pengguna layanan
                dengan maksud agar dapat membantu mempercepat tercapainya tujuan
                si pengguna jasa walau melanggar prosedur.
              </p>
            </div>
            <div className="col-span-1 lg:col-span-1 md:col-span-2">
              <div className="h-24 flex justify-center flex-col items-center">
                <img src="images/gratifikasi.png" alt="suap" className="h-24" />
              </div>
              <div className="font-medium text-lg">Gratifikasi</div>
              <p>
                Gratifikasi terjadi apabila pihak pengguna layanan memberikan
                sesuatu kepada pemberi layanan tanpa adanya penawaran,
                transaksi, atau deal untuk mencapai tujuan tertentu yang
                diinginkan. Biasanya hanya memberikan tanpa ada maksud apapun.
              </p>
            </div>
          </div>
        </div>
        <div class="custom-shape-divider-bottom-1688871457">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
      </section>
      <section className=" text-gray-600">
        <div className="px-8 pb-8 ">
          <h2 className="text-3xl mt-0 font-bold my-8 mb-8">
            Peraturan Terkait Gratifikasi
          </h2>
          <div className="flex md:flex-row flex-col gap-6">
            <div className="md:w-36 flex justify-center flex-col items-center">
              <img src="images/suap.png" alt="suap" className="h-24" />
            </div>
            <div className="flex flex-col space-y-2 text-left">
              <a
                href="https://jdihn.go.id/files/4/2001uu020.pdf"
                rel="noopener noreferrer"
                className="text-gray-600 no-underline text-lg flex"
                target="_blank"
              >
                <div>
                  <FaFileAlt className=" mr-2" />
                </div>
                <div>
                  <span className="font-medium">
                    Undang-Undang Nomor 20 Tahun 2001
                  </span>{" "}
                  tentang Pemberantasan Tindak Pidana Korupsi.{" "}
                </div>
              </a>
              <a
                href="https://peraturan.go.id/files/bn1438-2019.pdf"
                rel="noopener noreferrer"
                className="text-gray-600 no-underline text-lg flex"
                target="_blank"
              >
                <div>
                  <FaFileAlt className=" mr-2" />
                </div>
                <div>
                  <span className="font-medium">
                    Peraturan Komisi Pemberantasan Korupsi Nomor 2 Tahun 2019
                  </span>{" "}
                  tentang Pelaporan Gratifikasi.
                </div>
              </a>
              <a
                href="https://jdih.kemenkeu.go.id/download/ffc9f2e2-40d0-4ae3-adb9-cdc2cba0ae78/227~PMK.09~2021Per.pdf"
                rel="noopener noreferrer"
                className="text-gray-600 no-underline text-lg flex"
                target="_blank"
              >
                <div>
                  <FaFileAlt className=" mr-2" />
                </div>
                <div>
                  <span className="font-medium">
                    Peraturan Menteri Keuangan Nomor 227 Tahun 2021
                  </span>{" "}
                  tentang Pengendalian Gratifikasi Di Lingkungan Kementerian
                  Keuangan.
                </div>
              </a>
              <a
                href="https://www.slideshare.net/AMNIANGKATAN32/bahan-tayang-kmk-258-tahun-2022-ttg-juklak-gratifikasipptx"
                rel="noopener noreferrer"
                className="text-gray-600 no-underline text-lg flex"
                target="_blank"
              >
                <div>
                  <FaFileAlt className=" mr-2" />
                </div>
                <div>
                  <span className="font-medium">
                    Keputusan Menteri Keuangan Nomor 258 Tahun 2022
                  </span>{" "}
                  tentang Petunjuk Pelaksanaan Pengendalian Gratifikasi Di
                  Lingkungan Kementerian Keuangan.
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="px-8 pb-8 ">
          <h2 className="text-3xl mt-0 font-bold my-8 mb-8">Sanksi</h2>
          <div className="flex flex-col gap-6">
            <div className="flex justify-center flex-col items-center">
              <img src="images/sanksi.png" alt="Sanksi" className="h-24" />
            </div>
            <div className="flex flex-col space-y-2  items-center">
              <div className="text-lg text-center  max-w-xl mb-2 italic">
                Denda dengan{" "}
                <strong className="highlighted-text">
                  pidana penjara seumur hidup
                </strong>{" "}
                atau penjara{" "}
                <strong className="highlighted-text">
                  paling singkat 4 tahun dan paling lama 20 tahun
                </strong>{" "}
                dan pidana{" "}
                <strong className="highlighted-text">
                  denda paling sedikit Rp 200 juta dan paling banyak Rp 1
                  miliar.
                </strong>
              </div>
              <div className="text-base text-center  max-w-xl underline">
                Pasal 12B ayat (2) UU Nomor 31 Tahun 1999 jo UU Nomor 20 Tahun
                2001
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mx-auto pb-14 pt-6 bg-[#136bc6] flex flex-col items-center justify-center text-white">
        <AnimatedTextWord text={words[currIndex]} replay={replay} />
      </div>
      <Form className="mb-16" />
      <footer className=" pb-14 pt-6 bg-[#136bc6] text-white grid md:grid-cols-3 grid-cols-1 gap-2">
        <div></div>
        <div className="mx-auto flex flex-col items-center justify-center">
          {/* <div className="">
            <a href="https://www.hitwebcounter.com" target="_blank">
              <img
                src="https://hitwebcounter.com/counter/counter.php?page=8839810&style=0006&nbdigits=5&type=page&initCount=0"
                title="Counter Widget"
                Alt="Visit counter For Websites"
                border="0"
              />
            </a>
          </div> */}
          <div className="italic text-sm">
            Created by{" "}
            <Link
              href="https://instagram.com/aldryai?utm_source=abw_kemeneku"
              target="_blank"
              className="text-white font-medium not-italic"
            >
              @aldryai
            </Link>
            &nbsp;&{" "}
            <Link href="https://nuriz.id/?utm_source=abw_kemeneku">
              <img
                src="https://nuriz.id/badge/color_24.png"
                className="h-5 align-middle"
              />
            </Link>
          </div>
        </div>
        <div className=" text-sm">
          Telah dikunjungi <span>{page_view}</span> kali
        </div>
      </footer>
    </div>
  );
}

export default App;

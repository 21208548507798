import React from "react";
import ReactGA from "react-ga4";

export default function Link({ children, ...props }) {
  return (
    <a
      {...props}
      onClick={() => {
        ReactGA.event({
          category: "Link",
          action: "Click",
          label: props.href,
        });
      }}
    >
      {children}
    </a>
  );
}
